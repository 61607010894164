
// WARNING: DO NOT MODIIFY THIS FILE DIRECTLY
//
// THIS FILE IS GENERATED BY THE scripts/build-env.ts SCRIPT.
// ANY REQUIRED MODIFICATIONS SHOULD BE MADE THERE.
export const environment = {
  production: false,
  spacetime: {
      url: 'https://spacetime-v2.prod.andersonopt.com'
  },
  
  gridDetect: {
    url: 'https://grid-detect-staging.boron.andersonopt.io',
    ws: 'wss://grid-detect-staging.boron.andersonopt.io',
    searchTaskRn: 'team/task-grid-detect/task/grid-detect-grid-detect-search',
    env: 'processor-1'
  }
};
